import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { Helmet } from "react-helmet";
import TermsServicesContent from "../components/sections/TermsServicesContent/TermsServicesContent";

const TermsServices = () => {
  return (
    <>
      <Helmet>
        <title>Terms of services</title>
        <meta
          name="description"
          content="endshift is a solution of the last resort. It helps enterprises, small to large businesses, and startups to scale and remove any technological congestion."
        />
        <link rel="canonical" href="https://endshift.io/" />
      </Helmet>
      <Header dataColor="header-dark" />
      <TermsServicesContent />

      <Footer />
    </>
  );
};

export default TermsServices;
